import React from 'react';
import { Logo, LogoWrapper, Sect, Section } from './Sections.styled';
import logo from "../../../images/Logo.jpg"

const Sections = ({ backgroundColor, color, mobile }) => {
    return (
        <Sect backgroundColor={backgroundColor}>
            {!mobile && <LogoWrapper href='/'><Logo style={{backgroundImage: `url('${logo}')`}} /></LogoWrapper>}
            <Section color={color} href='/services'>Shippment Solutions</Section>
            <Section color={color} href='/staff'>Our Team</Section>
            <Section color={color} href='/contact'>Contact Us</Section>
        </Sect>
    );
};

export default Sections;
