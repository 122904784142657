import styled from "styled-components"


export const Sect = styled.nav`
    display: flex;
    background-image: linear-gradient(to bottom, ${props => props.backgroundColor ? props.backgroundColor : 'rgb(0, 0, 0, 0.8)'}, transparent);
    width: 100%;
    height: 120px;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    font-size: 40px;

    @media (max-width: 770px) {
        font-size: 30px;
    }

    @media (max-width: 500px) {
        font-size: 20px;
    }
`

export const Section = styled.a`
    text-decoration: none;
    color: ${props => props.color ? props.color : "white"};
`

export const Logo = styled.div`
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
`

export const LogoWrapper = styled.a`
    width: 20%;
    height: 100%;
`