import styled from "styled-components"



export const IconBox = styled.div`
    height: 100%;
    width: 20%;
    color: white;
    align-items: center;
    display: flex;

    @media (max-width: 770px) {
        margin: 10px;
        width: 50%;
    }
    
    & a {
        margin-right: 20px; 
    }
`

export const Icon = styled.div`
    margin: 10px;
    padding: 10px 8px 3px 10px;
    border: 2px solid white;
    border-radius: 40px;
    justify-content: center;
    align-items: center;
`

export const IconTitle = styled.h1`
    margin: 0px;
    font-size: 40px;
    line-height: 0.8;

    @media (max-width: 770px) {
        font-size: 20px;
    }
`

export const IconInfo = styled.h3`
    margin: 0px;
    color: rgb(251, 173, 3);
    font-size: 30px;

    @media (max-width: 770px) {
        font-size: 18px;
    }
`

export const Section = styled.div`
    height: 700px;
    width: 100%;
    display: block;
    align-items: center;
    justify-content: center;
    color: white;
    flex-direction: column;
    display: flex;
    margin-top: 10px;
`

export const Icons = styled.div`
    height: 20%;
    width: 100%;
    align-items: right;
    display: flex;
    justify-content: right;
    -webkit-justify-content: flex-end;

    @media (max-width: 770px) {
        width: 100%;
        margin-left: 0;
    }
`

export const Text = styled.p`
    margin-left: 8%;
    font-size: 28px;
    line-height: 1;
`

export const Left = styled.div`
    height: 90%;
    width: 40%;
    margin-top: 20px;
    background-image: url('${props => props.image}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: solid 1px rgb(251, 173, 3);
`

export const Right = styled.div`
    height: 80%;
    width: 60%;

    @media (max-width: 770px) {
        width: 100%;
    }
`

export const HelperText = styled.h4`
    font-size: 20px;
    margin-left: 8%;
    color: rgb(251, 173, 3);
`

export const Header = styled.h1`
    font-size: 80px;
    line-height: 0.7;
    margin-left: 8%;

    @media (max-width: 770px) {
        font-size: 50px;
    }
`
