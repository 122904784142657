import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Personnel from './Personnel/Personnel';
import Sections from '../HomePage/Sections/Sections';

const Staff = ({ mobile }) => {
    return (
        <>
            <Navbar mobile={mobile} />
            <Sections mobile={mobile} backgroundColor="rgb(251, 173, 3)" />
            <Personnel mobile={mobile} />
            <Footer mobile={mobile} />
        </>
    );
};

export default Staff;
