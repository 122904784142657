import React from 'react';
import { Section, Infos, Logo, LinkSectionTitle, Info, Links, Link, InfoText, LinkSection, AboutText } from './Footer.styled';
import { BsTelephoneFill } from "react-icons/bs"
import { ImLocation } from "react-icons/im"

const Footer = ({ mobile }) => {
    return (
        <Section>
            <Infos height="20%">
                {!mobile && (
                    <Info width="40%">
                        <Logo />
                    </Info>
                )}
                <Info width={mobile ? "50%" : "30%"}>
                    <BsTelephoneFill color="rgb(251, 173, 3)" size={mobile ? 25 : 35} />
                    <InfoText>Call Us<br />630-504-2688</InfoText>
                </Info>
                <Info width={mobile ? "50%" : "30%"}>
                    <ImLocation color="rgb(251, 173, 3)" size={mobile ? 25 : 35} />
                    <InfoText>Visit Us<br />Aurora, IL</InfoText>
                </Info>
            </Infos>
            <hr style={{ border: "1px solid white", width: "100%" }} />
            <Links>
                <LinkSection width={mobile ? "60%" : "40%"} padding={mobile ? "10px" : "10px 40px 10px 10px"}>
                    <LinkSectionTitle>About Us</LinkSectionTitle>
                    <AboutText>Vision International Inc. is a company with offices in USA and Europe. Our staff is on call working for you 24 hours a day, 7 days a week. We specialize in same or next day service for TL, LTL movements, and we pride ourselves on dependable, quality driven service for our customers.</AboutText>
                </LinkSection>
                <LinkSection width={mobile ? "40%" : "30%"}>
                    <LinkSectionTitle>Quick Links</LinkSectionTitle>
                    <Link href='/'>Our Trucks</Link>
                    <Link href='/services'>Shippment Solutions</Link>
                    <Link href='/staff'>Our Team</Link>
                    <Link href='/contact'>Contact</Link>
                </LinkSection>
                {!mobile && (
                    <LinkSection width="30%">
                        <LinkSectionTitle>Our Gallery</LinkSectionTitle>
                    </LinkSection>
                )}
            </Links>
        </Section>
    )
};

export default Footer;
