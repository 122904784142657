import styled from "styled-components"


export const Section = styled.div`
    height: 1800px;
    display: flex;
    color: white;
    flex-direction: column;
    align-items: top;
    background-color: black;
    padding-top: 120px;

    @media (max-width: 770px) {
        height: 4500px;
    }
`

export const Title = styled.h1`
    margin: 2% 70% 0% 5%;
    font-size: 90px;
    border-bottom: 8px solid rgb(251, 173, 3);

    @media (max-width: 1010px) {
        font-size: 50px;
        margin: 0 40% 0 10%;
        width: 20%;
    }

    @media (max-width: 770px) {
        font-size: 50px;
        margin: 0 40% 0 10%;
        width: 30%;
    }

    @media (max-width: 560px) {
        width: 50%;
    }
`

export const CardSubTitle = styled.h3`
    font-size: 26px;
    margin: 0px;
    text-align: center;
    line-height: 0.6;
`

export const CardContent = styled.p`
    font-size: 26px;
    margin: 20px;
    line-height: 0.6;
`

export const CardTitle = styled.h1`
    font-size: 40px;
    margin: 0px;
    text-align: center;
`

export const CardImage = styled.img`
    height: 150px;
    width: 150px;
    display: flex;
    margin: auto;
    margin-top: 30px;
    align-items: center;
    justify-content: center;
    border-radius: 75px;
    border: solid 2px black;

    @media (max-width: 770px) {
        height: 100px;
        width: 100px;
    }
`

export const Cards = styled.div`
    width: 90%;
    height: 80%;
    display: flex;
    flex-wrap: wrap;
    margin: 40px 5% 0% 5%;
    align-items: top;
    overflow-y: auto;

    /* width */
    &::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: rgb(40, 40, 40);
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgb(251, 173, 3); 
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }

    @media (max-width: 770px) {
        width: 100%;
        margin: 0;
        height: 100%;
    }
`

export const Card = styled.div`
    min-width: 280px;
    height: 350px;
    background-color: rgb(251, 173, 3);
    color: black;
    margin: 20px;
    border-radius: 15px;
    transition: 0.4s;
    box-shadow: 3px 3px 20px white;

    @media (max-width: 770px) {
        width: 50%;
        height: 300px;
        margin: 30px 25% 0 25%;
    }

    @media (max-width: 500px) {
        margin: 30px 5% 0 5%;
        width: 80%;
    }

    &:hover {
        transform: translateY(-3%);
        box-shadow: 6px 6px 10px rgb(251, 173, 3);
    }
`