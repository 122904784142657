import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Sections from '../HomePage/Sections/Sections';
import { Baner, Title, CardTitle, CardContent, ServiceCard, ServicesSection } from './Services.styled';
import { BiPhoneCall } from "react-icons/bi"
import { FaTruckLoading } from "react-icons/fa"
import { IoLogoUsd } from "react-icons/io"
import servicePage from "../../images/services_page.jpg"

const Services = ({ mobile }) => {
    return (
        <>
            <Navbar mobile={mobile} />
            <Sections mobile={mobile} />
            <Baner image={servicePage} >
                <Title>Your Best <a>Shippment</a> Solutions</Title>
            </Baner>
            <ServicesSection>
                <ServiceCard>
                    <FaTruckLoading size={mobile ? 25 : 90} color="black" />
                    <CardTitle>Capacity for Every Shipment</CardTitle>
                    <CardContent>Quickly find the right way to ship with multimodal digital freight matching and customized solutions when you need them.</CardContent>
                </ServiceCard>
                <ServiceCard>
                    <BiPhoneCall size={mobile ? 25 : 90} color="black" />
                    <CardTitle>Service You Can Count On</CardTitle>
                    <CardContent>Let our expertise be your competitive edge. Our service helps you deliver on promises to your customers and gain peace of mind.</CardContent>
                </ServiceCard>
                <ServiceCard>
                    <IoLogoUsd size={mobile ? 25 : 90} color="black" />
                    <CardTitle>Competitive Rates Instantly</CardTitle>
                    <CardContent>Stay on budget with transparent spot pricing that's true to the market every time you get a quote.</CardContent>
                </ServiceCard>
            </ServicesSection>
            <Footer mobile={mobile} />
        </>
    );
};

export default Services;
