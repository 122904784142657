import React from 'react';
import { Section, Right, Title, SubTitle, Header, Left, LearnMore, OurServices, Card, Image, CardDescription, CardSubtitle, CardTitle } from './Services.styled';
import services1 from "../../../images/services1.jpg"
import services2 from "../../../images/services2.jpg"
import services3 from "../../../images/services3.jpg"

const Services = ({ mobile }) => {
    return (
        <Section>
            <Header>
                <Left>
                    <SubTitle>What can we do for you</SubTitle>
                    <Title>Our Services</Title>
                </Left>
            </Header>
            <OurServices>
                <Card>
                    <Image style={{backgroundImage: `url(${services1})`}} />
                    <CardDescription>
                        <CardSubtitle>Dry Van</CardSubtitle>
                        {!mobile && <CardTitle>Dry Van</CardTitle>}
                    </CardDescription>
                </Card>
                <Card>
                    <Image style={{backgroundImage: `url(${services2})`}} />
                    <CardDescription>
                        <CardSubtitle>Flatbed</CardSubtitle>
                        {!mobile && <CardTitle>Flatbed</CardTitle>}
                    </CardDescription>
                </Card>
                <Card>
                    <Image style={{backgroundImage: `url(${services3})`}} />
                    <CardDescription>
                        <CardSubtitle>Reefer</CardSubtitle>
                        {!mobile && <CardTitle>Reefer</CardTitle>}
                    </CardDescription>
                </Card>
            </OurServices>
        </Section>
    );
};

export default Services;
