import styled from "styled-components"


export const Section = styled.div`
    height: 800px;
    display: flex;
    width: 100%;
    color: white;
    margin-top: 20px;
    flex-direction: column;

    @media (max-width: 770px) {
        margin: 0px;
    }
`

export const Header = styled.div`
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0px 40px 0px;
    flex-direction: column;
`

export const Title = styled.h1`
    color: white;
    font-size: 70px;
    margin: 0px;
`

export const SubTitle = styled.h4`
    color: rgb(251, 173, 3);
    font-size: 30px;
    margin: 0px;
    padding-top: 20px;
`

export const Cards = styled.div`
    width: 90%;
    height: 90%;
    margin: auto;

    @media (max-width: 770px) {
        padding: 10px;
    }
`

export const Card = styled.div`
    width: 40%;
    height: 90%;
    background: white;
    display: flex;

    @media (max-width: 770px) {
        width: 100%;
        height: 20%;
    }
`

export const Row = styled.div`
    display: flex;
    width: 100%;
    height: 50%;
    justify-content: space-evenly;
`

export const CardTitle = styled.h1`
    color: rgb(251, 173, 3);
    font-size: 50px;
    line-height: 0.8;
    margin: 30px 20px 0px 0px;

    @media (max-width: 770px) {
        font-size: 30px;
        margin: 10px 20px 0px 0px;
    }
`


export const Content = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 20px 0px 0px 30px;

    @media (max-width: 770px) {
        margin: 10px 0px 0px 30px;
    }
`

export const Image = styled.div`
    width: 50%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`