import styled from "styled-components"


export const Section = styled.div`
    height: 600px;
    display: flex;
    color: white;
    flex-direction: column;
    width: 95%;
    align-items: center;
    justify-content: center;
    margin: auto;
`

export const Title = styled.h1`
    color: white;
    font-size: 70px;
    margin: 0px;

    @media (max-width: 770px) {
        font-size: 38px;
    }
`

export const SubTitle = styled.h4`
    color: rgb(251, 173, 3);
    font-size: 30px;
    margin: 0px;

    @media (max-width: 770px) {
        font-size: 20px;
    }
`

export const Header = styled.div`
    width: 100%;
    height: 20%;
    display: flex;
`

export const Left = styled.div`
    width: 70%;
`

export const Right = styled.div`
    width: 30%;
    justify-content: right;
    align-items: center;
    display: flex;
`

export const LearnMore = styled.button`
    padding: 10px 20px 10px 20px;
    background-color: rgb(251, 173, 3);
    font-weight: bold;
    border-radius: 5px;
    font-size: 17px;
    cursor: pointer;

    @media (max-width: 770px) {
        padding: 5px 10px 5px 10px;
    }
`


export const OurServices = styled.div`
    height: 80%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 10px;

    @media (max-width: 770px) {
        flex-direction: column;
    }
`


export const Card = styled.div`
    height: 100%;
    width: 30%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;

    @media (max-width: 770px) {
        height: 30%;
        width: 100%;
    }
`

export const Image = styled.div`
    height: 80%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 770px) {
        width: 70%;
    }
`

export const CardDescription = styled.div`
    height: 15%;
    width: 100%;
    border-left: 6px solid rgb(251, 173, 3);
    align-items: center;
    line-height: 1.2;

    @media (max-width: 770px) {
        width: 70%;
    }
`

export const CardSubtitle = styled.h3`
    font-size: 23px;
    margin: 0px;
    padding-left: 20px;
`

export const CardTitle = styled.h1`
    font-size: 35px;
    margin: 0px;
    padding-left: 20px;
`
