import styled from "styled-components"


export const Section = styled.div`
    height: 400px;
    display: flex;
    color: white;
    margin-top: 20px;
    flex-direction: column;
    margin: 0px 70px 0px 70px;

    @media (max-width: 770px) {
        margin: 0px;
    }
`

export const Infos = styled.div`
    display: flex;
    height: ${props => props.height};
    width: 100%;
`

export const InfoText = styled.div`
    margin-left: 20px;
    line-height: 0.8;
`

export const Info = styled.div`
    display: flex;
    height: 100%;
    width: ${props => props.width};
    align-items: center;
    justify-content: center;
    font-size: 30px;
`

export const Links = styled.div`
    display: flex;
    height: 70%;
    width: 100%;
    padding-top: 20px;
`

export const LinkSection = styled.div`
    display: flex;
    flex-direction: column;
    height: 70%;
    width: ${props => props.width};
    padding: ${props => props.padding ? props.padding : "10px"};
`

export const LinkSectionTitle = styled.h3`
    font-size: 40px;
    margin: 0px;
    line-height: 0.8;
`

export const AboutText = styled.p`
    font-size: 24px;
    line-height: 0.8;
`

export const Logo = styled.div`
    width: 40%;
    height: 100%;
    background-image: url('../../../static/images/Logo.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`

export const Link = styled.a`
    font-size: 24px;
    padding-left: 20px;
    text-decoration: none;
    color: white;
`
