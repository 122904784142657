import styled from "styled-components"


export const Baner = styled.div`
    width: 100%;
    height: 700px;
    background-image: url('${props => props.image}');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    & h1 {
        font-size: 100px;
        color: white;
    }

    @media (max-width: 770px) {
        & h1 {
            font-size: 50px;
        }
    }
`

export const ContactUs = styled.div`
    width: 100%;
    height: ${props => props.space + 900}px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const ContactTitle = styled.h1`
    font-size: 70px;
    border-bottom: solid 4px rgb(251, 173, 3);
    width: 30%;
    margin: 20px 60% 0 10%;
    height: 100px;

    @media (max-width: 1100px) {
        width: 40%;
    }

    @media (max-width: 830px) {
        font-size: 50px;
        margin: 0;
        width: 50%;
    }

    @media (max-width: 540px) {
        width: 80%;
    }
`

export const Infos = styled.div`
    height: 630px;
    width: 90%;
    margin-top: 50px;
    margin-bottom: 20px;

    @media (max-width: 770px) {
        width: 100%;
    }
`

export const Info = styled.div`
    height: 25%;
    width: 70%;
    background-color: white;
    margin: auto;
    border: solid 1px black;
    display: flex;
    align-items: center;
    ${props => props.animate && "animation: fadeOut 0.5s ease-out forwards;"}

    @media (max-width: 770px) {
        width: 100%;
    }

    & h1 {
        margin: 0 0 0 5%;
        width: 80%;
        color: black;
        font-size: 70px;
        line-height: 0.8;
    }

    & button {
        height: 100%;
        width: 15%;
        border: none;
        cursor: pointer;
        background-color: inherit;
    }

    @keyframes fadeOut {
        from {
            background-color: rgb(251, 173, 3);
            height: 35%;
        }
        to {
            background-color: white;
            height: 25%;
        }
    }

`

export const InfoExtended = styled.div`
    width: 70%;
    background-color: rgb(251, 173, 3);
    margin: auto;
    border: solid 1px black;
    display: flex;
    align-items: top;
    animation: fade 0.5s ease-out;
    height: 35%;

    & h1 {
        color: black;
        font-size: 70px;
        margin: 0;
        margin-top: 20px;
        animation: headerAnimation 0.5s ease-out;
        line-height: 0.8;
    }

    & div {
        margin: 0 0 0 5%;
        width: 80%;
        height: 100%;
        animation: textAnimation 0.5s ease-out;
    }

    & p {
        font-size: 30px;
        color: black;
        margin: 0;
        animation: pAnimation 0.7s ease-in;
    }

    & button {
        height: 100%;
        width: 15%;
        border: none;
        cursor: pointer;
        background-color: inherit;
    }

    @media (max-width: 920px) {
        & p {
            font-size: 25px;
            line-height: 1.2;
        }

        & h1 {
            font-size: 60px;
        }
    }

    @media (max-width: 770px) {
        width: 100%;
    }

    @keyframes fade {
        from {
            background-color: white;
            max-height: 25%;
        }
        to {
            background-color: rgb(251, 173, 3);
            max-height: 35%;
        }
    }

    @keyframes textAnimation {
        from {
            color: rgb(251, 173, 3);
            max-height: 50%;
        }
        to {
            color: black;
            max-height: 100%;
        }
    }

    @keyframes headerAnimation {
        from {
            max-height: 100%;
        }
        to {
            max-height: 60%;
        }
    }

    @keyframes pAnimation {
        from {
            max-height: 0%;
            opacity: 0;
        }
        to {
            max-height: 40%;
            opacity: 1;
        }
    }


`