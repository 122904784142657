import React from 'react';
import { IoMdCloudDownload } from "react-icons/io"
import { FiPhoneCall } from "react-icons/fi"
import { Section, IconBox, Icon, IconTitle, IconInfo, Icons, Text, Left, Right, HelperText, Header } from "./About.styled"
import left from "../../../images/section_1.jpg"


const About = ({ mobile }) => {
    return (
        <Section>
            <div style={{ display: "flex", width: "90%" }}>
                {!mobile && <Left image={left} />}
                <Right>
                    <HelperText>Let us deliver on time.</HelperText>
                    <Header>
                        Creating a sustainable future one delivery at a time.
                    </Header>
                    <Text>
                        Vision International Inc. is a company with offices in USA and Europe. Our staff is on call working for you 24 hours a day, 7 days a week. We specialize in same or next day service for TL, LTL movements, and we pride ourselves on dependable, quality driven service for our customers.
                    </Text>
                </Right>
            </div>
            <Icons>
                <IconBox>
                    <a href="/" style={{ pointer: "cursor" }}>
                        <Icon>
                            <IoMdCloudDownload size={30} color='rgb(251, 173, 3)' />
                        </Icon>
                    </a>
                    <div className='col'>
                        <IconTitle>MC</IconTitle>
                        <IconInfo>MC 550806</IconInfo>
                    </div>
                </IconBox>
                <IconBox>
                    <Icon>
                        <FiPhoneCall size={30} color='rgb(251, 173, 3)' />
                    </Icon>
                    <div className='col'>
                        <IconTitle>Call Us</IconTitle>
                        <IconInfo>+1 630 504 2688</IconInfo>
                    </div>
                </IconBox>
            </Icons>
        </Section>
    );
};

export default About;
