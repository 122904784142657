import React from 'react';
import { Header, CardTitle, Image, Card, Row, Section, Cards, Title, SubTitle, Content } from './Features.styled';
import { HiTruck } from "react-icons/hi"
import { GiTakeMyMoney } from "react-icons/gi"
import { RiCustomerService2Fill } from "react-icons/ri"
import { AiOutlineFieldTime } from "react-icons/ai"
import heavySupport from "../../../images/heavy_support.jpg"
import lowCharges from "../../../images/low_charges.jpg"
import serviceSupport from "../../../images/service_support.jpg"
import onTime from "../../../images/on_time.jpg"

const Features = ({ mobile }) => {

    return (
        <Section>
            <Header>
                <SubTitle>Our Features</SubTitle>
                <Title>Our Features</Title>
            </Header>
            <Cards>
                {mobile ? (
                    <>
                        <Card>
                            <Content>
                                <HiTruck size={30} color='black' />
                                <CardTitle>Heavy Support</CardTitle>
                            </Content>
                            <Image style={{backgroundImage: `url('${heavySupport}')`}} />
                        </Card>
                        <Card>
                            <Content>
                                <GiTakeMyMoney size={30} color='black' />
                                <CardTitle>Low transport charges</CardTitle>
                            </Content>
                            <Image style={{backgroundImage: `url('${lowCharges}')`}} />
                        </Card>
                        <Card>
                            <Content>
                                <RiCustomerService2Fill size={30} color='black' />
                                <CardTitle>Service support</CardTitle>
                            </Content>
                            <Image style={{backgroundImage: `url('${serviceSupport}')`}} />
                        </Card>
                        <Card>
                            <Content>
                                <AiOutlineFieldTime size={30} color='black' />
                                <CardTitle>On time delivery</CardTitle>
                            </Content>
                            <Image style={{backgroundImage: `url('${onTime}')`}} />
                        </Card>
                    </>
                ) :
                    (
                        <>
                            <Row>
                                <Card>
                                    <Content>
                                        <HiTruck size={80} color='black' />
                                        <CardTitle>Heavy Support</CardTitle>
                                    </Content>
                                    <Image style={{backgroundImage: `url('${heavySupport}')`}} />
                                </Card>
                                <Card>
                                    <Content>
                                        <GiTakeMyMoney size={80} color='black' />
                                        <CardTitle>Low transport charges</CardTitle>
                                    </Content>
                                    <Image style={{backgroundImage: `url('${lowCharges}')`}} />
                                </Card>
                            </Row>
                            <Row>
                                <Card>
                                    <Content>
                                        <RiCustomerService2Fill size={80} color='black' />
                                        <CardTitle>Service support</CardTitle>
                                    </Content>
                                    <Image style={{backgroundImage: `url('${serviceSupport}')`}} />
                                </Card>
                                <Card>
                                    <Content>
                                        <AiOutlineFieldTime size={80} color='black' />
                                        <CardTitle>On time delivery</CardTitle>
                                    </Content>
                                    <Image style={{backgroundImage: `url('${onTime}')`}} />
                                </Card>
                            </Row>
                        </>
                    )}
            </Cards>
        </Section>
    );
};

export default Features;
