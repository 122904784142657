import React, { useEffect, useState } from 'react';
import { Section, CardContent, Title, CardSubTitle, CardImage, CardTitle, Cards, Row, Card } from './Personnel.styled';

const Personnel = ({ mobile }) => {
    const employees = [
        {
            "first_name": "Robert",
            "last_name": "Matracki",
            "role": "Owner",
            "email": "robert@vi4u.net",
            "phone": "630-504-2688"
        },
        {
            "first_name": "Urszula",
            "last_name": "Kaczynski",
            "role": "Safety Director",
            "email": "safety@vi4u.net",
            "phone": "630-504-2688 ext. 408"
        }, 
        {
            "first_name": "Yurii",
            "last_name": "Kostiukov",
            "role": "Operations Manager",
            "email": "yurii@vi4u.net",
            "phone": "630-504-2688 ext. 406"
        }, 
        {
            "first_name": "Olga",
            "last_name": "Holtvianska",
            "role": "Office Manager",
            "email": "olga@vi4u.net",
            "phone": "630-504-2688 ext. 402"
        },
        {
            "first_name": "Kamila",
            "last_name": "Starynets",
            "role": "Accounting",
            "email": "kamila@vi4u.net",
            "phone": "630-504-2688 ext. 410"
        },  
        {
            "first_name": "Mike",
            "last_name": "Gladysz",
            "role": "Dispatcher",
            "email": "mike@vi4u.net",
            "phone": "630-504-2688 ext. 401"
        }, 
        {
            "first_name": "Martin",
            "last_name": "Wiciak",
            "role": "Dispatcher",
            "email": "martin@vi4u.net",
            "phone": "630-504-2688 ext. 404"
        }, 
        {
            "first_name": "Adrianna",
            "last_name": "Dolgan",
            "role": "Dispatcher",
            "email": "adrianna@vi4u.net",
            "phone": "630-504-2688 ext. 409"
        }, 
        {
            "first_name": "Andrew",
            "last_name": "Lewiarz",
            "role": "Dispatcher",
            "email": "andrew@vi4u.net",
            "phone": "630-504-2688 ext. 414"
        }, 
        {
            "first_name": "Francis",
            "last_name": "Bee",
            "role": "Dispatcher",
            "email": "francis@vi4u.net",
            "phone": "630-504-2688 ext. 415"
        }, 
    ]

    const CardComponent = ({ name, role, email, phone, image }) => {
        return (
            <Card>
                <CardImage src={image} />
                <CardTitle>{name}</CardTitle>
                <CardSubTitle>{role}</CardSubTitle>
                <CardContent>
                    email: {email}<br />
                    phone: {phone}
                </CardContent>
            </Card>
        );
    };
    return (
        <Section>
            <Title>Our Team</Title>
            <Cards>
                {
                    employees.map((emp) => (
                        <CardComponent name={`${emp.first_name}`} image={`images/employees/${emp.first_name.toLocaleLowerCase()}.jpg`} role={emp.role} email={emp.email} phone={emp.phone} />
                    ))
                }
            </Cards>
        </Section>
    );
};

export default Personnel;
