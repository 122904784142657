import React from 'react'
import { Nav, Left, Right, FollowUs, NavItem, Logo, LogoWrapper } from './Navbar.styled'
import { FaTwitter, FaFacebookF, FaLinkedinIn } from "react-icons/fa"

const Navbar = ({ mobile }) => {
    return (
        <Nav>
            <Left>
                {mobile ? (
                    <LogoWrapper href='/'><Logo /></LogoWrapper>
                ) : (
                    null
                )}
            </Left>
            <Right>
                <NavItem href='/contact'>
                    Get Quote
                </NavItem>

                <NavItem href='/contact'>
                    Track Shipment
                </NavItem>
            </Right>
        </Nav>
    )
}

export default Navbar
