import styled from "styled-components"

export const HomePageBaner = styled.div`
    width: 100%;
    height: 700px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
`

export const LoadBoardButton = styled.button`
    padding: 10px 20px 10px 20px;
    background-color: rgb(251, 173, 3);
    font-weight: bold;
    border-radius: 5px;
    font-size: 17px;
    cursor: pointer;
    box-shadow: 3px 3px 8px 1px;
`


export const Baner = styled.h1`
    color: white;
    font-size: 140px;
    margin: 0px;
    text-align: center;

    @media (max-width: 770px) {
        font-size: 50px;
    }
`
