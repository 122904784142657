import styled from "styled-components"


export const Baner = styled.div`
    width: 100%;
    height: 700px;
    background-image: url('${props => props.image}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    color: white;
`

export const Title = styled.h1`
    font-size: 100px;
    margin: 0px;
    text-align: center;

    & a {
        color: rgb(251, 173, 3);
    }

    @media (max-width: 770px) {
        font-size: 50px;
    }
`

export const Icon = styled.div`
    width: 100%;
    height: 40%;
`

export const ServicesSection = styled.div`
    height: 400px;
    width: 100%;
    background-color: rgb(251, 173, 3);
    display: flex;
    padding: 20px 0px 20px 0px;
    justify-content: space-evenly;
    align-items: center;

    @media (max-width: 770px) {
        flex-direction: column;
        height: 500px;
    }
`

export const ServiceCard = styled.div`
    height: 80%;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 770px) {
        height: 30%;
        width: 100%;
    }
`

export const CardTitle = styled.h1`
    text-align: center;
    font-size: 36px;
    margin: 0px;

    @media (max-width: 770px) {
        font-size: 28px;
    }
`

export const CardContent = styled.p`
    font-size: 28px;
    margin: 20px 30px 0px 30px;
    text-align: center;

    @media (max-width: 770px) {
        font-size: 18px;
        margin: 0;
    }
`
