import React from 'react'
import Navbar from "../Navbar/Navbar"
import Sections from "./Sections/Sections"
import { HomePageBaner, LoadBoardButton, Baner } from './HomePage.styled'
import About from './About/About'
import Services from './Services/Services'
import Features from './Features/Features'
import Footer from '../Footer/Footer'
import homepage from "../../images/homepage.jpg"

const HomePage = ({ mobile }) => {

    return (
        <>
            <Navbar mobile={mobile} />
            <Sections mobile={mobile} />
            <HomePageBaner style={{backgroundImage: `url('${homepage}')`}}>
                <Baner>Best Moves for your <a style={{ color: "rgb(251,173,3)" }}>Business</a>.</Baner>
                <LoadBoardButton><a href='https://www.mobi.magda-trans.pl' target='_blank' style={{ textDecoration: 'none', color: '#fff' }}>Loadboard<sup>(BETA)</sup></a></LoadBoardButton>
            </HomePageBaner>
            <About mobile={mobile} />
            <Services mobile={mobile} />
            <Features mobile={mobile} />
            <Footer mobile={mobile} />
        </>
    )
}

export default HomePage
