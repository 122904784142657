import styled from "styled-components"


export const Logo = styled.div`
    background-image: url('../../../static/images/Logo.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
`

export const LogoWrapper = styled.a`
    width: 100px;
    height: 100%;
`

export const Nav = styled.nav`
    display: flex;
    background-color: rgb(0,0,0, 0.9);
    width: 100%;
    height: 55px;
    color: white;
`

export const FollowUs = styled.h4`
    font-size: 28px;
    margin-right: 20px;
`

export const Left = styled.div`
    justify-content: space-evenly;
    width: 25%;
    font-size: 40px;
    display: flex;
    padding-left: 40px;
    align-items: center;

    @media (max-width: 770px) {
        width: 100%;
    }

    & a {
        display: flex;
        align-items: center;
        color: white;
    }
`

export const Right = styled.div`
    -webkit-justify-content: flex-end;
    width: 75%;
    font-size: 28px;
    display: flex;
    padding-right: 20x;
    align-items: center;

    @media (max-width: 770px) {
        font-size: 20px;
    }
`

export const NavItem = styled.a`
    padding: 20px;
    color: white;
    background-color: inherit;
    font-family: inherit;
    font-size: inherit;
    border: none;
    cursor: pointer;
    height: 100%;
    align-items: center;
    display: flex;
    text-decoration: none;
`
