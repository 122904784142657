import React, { useState } from "react";
import HomePage from "./components/HomePage/HomePage";
import Staff from "./components/Staff/Staff";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Services from "./components/Services/Services";
import Contact from "./components/Contact/Contact";
import "./index.css"


const App = () => {
    const [mobile, setMobile] = useState((window.innerWidth <= 770));

    const handleResize = () => {
        if (window.innerWidth <= 770) {
            setMobile(true)
        } else {
            setMobile(false)
        }
    }
    window.addEventListener('resize', handleResize)

    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<HomePage mobile={mobile} />} />
                <Route exact path="/staff" element={<Staff mobile={mobile} />} />
                <Route exact path="/services" element={<Services mobile={mobile} />} />
                <Route exact path="/contact" element={<Contact mobile={mobile} />} />
            </Routes>
        </Router>
    );
}

export default App