import React, { useEffect, useState } from 'react';
import Footer from "../Footer/Footer"
import Navbar from "../Navbar/Navbar"
import Sections from '../HomePage/Sections/Sections';
import { Baner, ContactUs, ContactTitle, Infos, Info, InfoExtended } from "./Contact.styled";
import { GoPlus } from "react-icons/go"
import { HiMinus } from "react-icons/hi"
import baner from "../../images/Contact.jpg"

const Contact = ({ mobile }) => {

    const cards = [
        {
            "id": 1,
            "title": "Corporate",
            "show": false,
            "mounted": false,
            "message": <p>Address: 1005 North Commons Drive, Aurora, IL 60504<br />Office: +1 630 504 2688<br />email: office@vi4u.net</p>
        },
        {
            "id": 2,
            "title": "Claim",
            "show": false,
            "mounted": false,
            "message": <p>Robert Matracki <br />phone: +1 630 504 2688<br />email: robert@vi4u.net</p>
        },
        {
            "id": 3,
            "title": "Owner Operators",
            "show": false,
            "mounted": false,
            "message": <p>Robert Matracki <br />phone: +1 630 504 2688<br />email: robert@vi4u.net</p>
        },
        {
            "id": 4,
            "title": "Shippers",
            "show": false,
            "mounted": false,
            "message": <p>Andrew <br />phone: +1 630 504 2688<br />email: andrew@vi4u.net</p>
        },
    ]
    const [items, setItems] = useState(cards);
    const [prev, setPrev] = useState(-1);
    const [shownItems, setShownItems] = useState(0);

    const handleOnClick = i => {

        if (items[i].show === false) {
            setShownItems(shownItems + 1);
        } else {
            setShownItems(shownItems - 1);
        }

        setItems(state => {
            const list = state.map((item, j) => {
                if (j === i) {
                    setPrev(i);
                    return { ...item, "show": !item.show };
                } else {
                    return item;
                }
            });

            return list;
        });
    };

    return (
        <>
            <Navbar mobile={mobile} />
            <Sections mobile={mobile} backgroundColor="black" />
            <Baner image={baner} >
                <h1>24/7 at <a style={{ color: "rgb(251, 173, 3)" }}>Your</a> service.</h1>
            </Baner>
            <ContactUs space={shownItems * 50}>
                <ContactTitle>Find inforamtion</ContactTitle>
                <Infos>
                    {
                        items.map((info) => (
                            <>
                                {info.show ? (
                                    <InfoExtended>
                                        <div>
                                            <h1>{info.title}</h1>
                                            {info.message}
                                        </div>
                                        <button onClick={() => handleOnClick(info.id - 1)}>
                                            <HiMinus color='black' size={30} />
                                        </button>
                                    </InfoExtended>
                                ) : (
                                    <Info animate={info.id == prev + 1}>
                                        <h1>{info.title}</h1>
                                        <button onClick={() => handleOnClick(info.id - 1)}>
                                            <GoPlus color='black' size={30} />
                                        </button>
                                    </Info>
                                )
                                }
                            </>
                        ))
                    }
                </Infos>
            </ContactUs>
            <Footer mobile={mobile} />
        </>
    );
};

export default Contact;
